import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Form, Formik, FormikHelpers } from 'formik';
import { Alert, Notification, Button, Fx, Input } from 'Components';
import {
	COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP,
	COGNITO_ERROR_MSG
} from 'app-config';

import { useStores } from 'Hooks/useStore';
import {
	SignInRequest,
	CognitoResponseError,
	AuthUser
} from 'Services/Api/Auth/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import ChangePasswordForm, {
	ChangePasswordFormProps,
	SetShouldMoveToTermsAndConditions
} from './Steps/ChangePasswordForm';
import { AuthState, PhoneNumber } from '../Auth.types';
import { firstLoginScreenSchema, loginSchema } from './schema';
import AcceptTermsAndConditions from './Steps/AcceptTermsAndConditions';

type LoginValues = SignInRequest;

type FirstLoginValues = SignInRequest & PhoneNumber;

type Values = LoginValues | FirstLoginValues;

export interface LoginProps {
	firstTimeSignIn?: boolean;
}
type CognitoUser = AuthUser;

export interface LoginFormProps
	extends LoginProps,
		SetShouldMoveToTermsAndConditions {
	setCognitoUser(user: CognitoUser | null): void;
}
export interface LoginFormWrapProps
	extends LoginFormProps,
		ChangePasswordFormProps {
	shouldMoveToTermsAndConditions: boolean;
}
/*
const ssoCheck = window.location.search;
const ssoParams = new URLSearchParams(ssoCheck);
if(ssoParams.has('sso')){
	const rawcreds = atob(ssoParams.get('sso')!);
	const Activatesso = async function (){
		const creds = rawcreds.split(':');
		const ssologin: SignInRequest = {
			login: creds[0],
			password: creds[1]
		}
		const { authStore } = useStores();
		const history = useHistory<AuthState>();
		localStorage.clear();
		const user = await authStore.signIn(ssologin);
		const url = getAppRoutes().HOME;
		const historyState = {
			userLoggedIn: true
		};
		history.push(url, historyState);
	}
	Activatesso();
}
*/
const LoginForm = observer(
	({
		firstTimeSignIn,
		setCognitoUser,
		setShouldMoveToTermsAndConditions
	}: LoginFormProps) => {
		const { authStore } = useStores();
		const history = useHistory<AuthState>();

		const { t } = useTranslation();

		const forgotPassword = useCallback(() => {
			history.push(getAppRoutes().RESET_PASSWORD);
		}, [history]);

		useEffect(() => {
			if (authStore.currentUser?.isConsentGiven === false) {
				setCognitoUser((authStore.currentUser as unknown) as AuthUser);
				setShouldMoveToTermsAndConditions(true);
			}
			// do not include `setCognitoUser, setShouldMoveToTermsAndConditions`
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [authStore.currentUser?.isConsentGiven]);

		const ssoCheck = window.location.search;
		const ssoParams = new URLSearchParams(ssoCheck);
		if(ssoParams.has('sso')){	
			localStorage.clear();
			(async () => {
				const rawcreds = atob(ssoParams.get('sso')!);
				const creds = rawcreds.split(':');
				const ssologin: SignInRequest = {
					login: creds[0],
					password: creds[1]
				}
				const user = await authStore.signIn(ssologin);
				const url = getAppRoutes().HOME;
				const historyState = {
					userLoggedIn: true
				};
				history.push(url, historyState);
			})();
		}

		const onSubmit = async (
			values: Values,
			helpers: FormikHelpers<Values>
		) => {
			helpers.setStatus('');
			try {
				localStorage.clear();
				const user = await authStore.signIn(values);

				if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					setCognitoUser(user);
				} else {
					const url =
						history.location.state?.returnUrl ??
						getAppRoutes().HOME;
					const historyState = {
						userLoggedIn: true
					};
					history.push(url, historyState);
				}
			} catch (e) {
				const error = e as CognitoResponseError;
				helpers.setSubmitting(false);

				if (
					error.message ===
					COGNITO_ERROR_MSG.TEMPORARY_PASSWORD_EXPIRED
				) {
					history.push(getAppRoutes().RESET_TEMPORARY_PASSWORD, {
						cognitoLoginTemporaryPasswordExpired: values.login
					});
					return;
				}

				const cognitoError =
					COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP[error.message];

				if (cognitoError) {
					helpers.setStatus(t(cognitoError));
					return;
				}

				switch (error.code) {
					case 'NotAuthorizedException':
					case 'UserNotFoundException':
						helpers.setStatus(t('auth.login.errors.notAuthorized'));
						break;
					default:
						Notification.error({
							description: t('errorCodes.genericErrorMessage')
						});
						break;
				}
			}
		};

		const initialValues: Values = {
			login: '',
			password: ''
		};

		const schema = firstTimeSignIn ? firstLoginScreenSchema : loginSchema;

		const checktenanturl = window.location.href;
		let redirecturltext1 = '';
		let redirecturltext2 = '';
		let redirecturltext3 = '';
		let redirecturltextheader = '';
		let urltext = '';
		let title = t('auth.login.title');
		if(checktenanturl.includes(".com/logi")){
			redirecturltextheader = "Client Notice";
			redirecturltext1 = 'You\'ve reached an administrative page of the VENZA System.';
			redirecturltext2 = 'To log in, you must navigate to your organisation\'s designated portal using its custom URL. Typically, custom URLs use the following format: ';
			urltext = 'venzagrc.com/companyname';
			redirecturltext3 = 'If you do not have your custom URL or need assistance, contact your organisational training or IT security representative.';
			title = '';
		}
		let showsso = false;
		let ssolink = '';
		let ssoimage = '';
		let alttext = '';
		if (window.localStorage.getItem("tenantsso") == null) {
			window.localStorage.setItem("tenantsso","false"); 
		}
		
		const checksso = window.localStorage.getItem("tenantsso");
		if(checksso!.includes('"sso":tru')){
			showsso = true;
		}

		if(showsso){
			let env = 'prod';
			let tenant_id = [];
			const tenanturl = window.location.href;
			if(tenanturl.includes('stg.v')){
				env = 'stage';
			}
			if(tenanturl.includes('dev.v') || tenanturl.includes(':300')){
				env = 'dev';
			}
			tenant_id = window.location.href.split("/");
			const z = tenant_id.length - 2;
			ssolink = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=1bd5cb44-e6d1-4d9d-bf81-dd9a012c59a2&response_type=code&redirect_uri=https%3A%2F%2Fauth-'+env+'.venzagrc.com%2Fsso%2Fvalidate&response_mode=form_post&scope=User.Read&state='+tenant_id[z];
			ssoimage = 'https://api.venzagroup.com/images/sign-in-ms-button.svg';
			alttext = 'Sign-In with Microsoft';
		}

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				{({ isValid, dirty, isSubmitting, status, setFieldValue }) => (
					<>
						<div style={{width:'100%',textAlign:'center',fontSize:'20px',fontWeight:'bold',paddingBottom:'5px'}}>
							{redirecturltextheader}
						</div>
						<div style={{width:'100%',textAlign:'left',paddingBottom:'10px'}}>
							{redirecturltext1}
						</div>
						<div style={{width:'100%',textAlign:'left',paddingBottom:'10px'}}>
							{redirecturltext2}<span style={{fontWeight:'bold'}}>{urltext}</span>
						</div>
						<div style={{width:'100%',textAlign:'left',paddingBottom:'15px'}}>
							{redirecturltext3}
						</div>
						<h1>{title}</h1>
						{status && (
							<Alert
								showIcon
								message=""
								description={status}
								type="error"
							/>
						)}
						<Form>
							<Input.FormikField
								name="login"
								label={t('auth.login.inputs.login')}
								autoComplete="off"
								required={firstTimeSignIn}
								onBlur={({ target }) => {
									setFieldValue(
										'login',
										target.value.trim(),
										true
									);
								}}
								onChange={({ target }) => {
									setFieldValue(
										'login',
										target.value.trim(),
										true
									);
								}}
								onPaste={({ target }) => {
									setTimeout(() => {
										setFieldValue(
											'login',
											// @ts-ignore
											target.value.trim(),
											true
										);
									}, 0);
								}}
							/>
							<Input.PasswordFormikField
								name="password"
								required={firstTimeSignIn}
								label={t(
									`auth.login.inputs.${
										firstTimeSignIn
											? 'temporaryPassword'
											: 'password'
									}`
								)}
							/>
							{!firstTimeSignIn && (
								<Fx justify="end">
									<Button
										type="text"
										link
										thin
										onClick={forgotPassword}
									>
										{t('auth.login.forgotYourPassword')}
									</Button>
								</Fx>
							)}

							<div>
								<Button
									htmlType="submit"
									type="primary"
									shape="round"
									fullWidth
									disabled={!(isValid && dirty)}
									loading={isSubmitting}
								>
									{t('auth.login.submitText')}
								</Button>
							</div>
						</Form>
						<div style={{width:'100%',textAlign:'center',paddingTop:'30px',paddingBottom:'15px'}}>
							<a href={ssolink}>	
								<img src={ssoimage} alt={alttext} />
							</a>
						</div>
					</>
				)}
			</Formik>
		);
	}
);

export function LoginFormWrap({
	firstTimeSignIn,
	setCognitoUser,
	cognitoUser,
	shouldMoveToTermsAndConditions,
	setShouldMoveToTermsAndConditions
}: LoginFormWrapProps) {
	if (cognitoUser) {
		if (shouldMoveToTermsAndConditions)
			return (
				<AcceptTermsAndConditions
					setShouldMoveToTermsAndConditions={
						setShouldMoveToTermsAndConditions
					}
					setCognitoUser={setCognitoUser}
				/>
			);

		return (
			<ChangePasswordForm
				cognitoUser={cognitoUser}
				setShouldMoveToTermsAndConditions={
					setShouldMoveToTermsAndConditions
				}
			/>
		);
	}

	return (
		<LoginForm
			firstTimeSignIn={firstTimeSignIn}
			setShouldMoveToTermsAndConditions={
				setShouldMoveToTermsAndConditions
			}
			setCognitoUser={setCognitoUser}
		/>
	);
}

export default LoginFormWrap;