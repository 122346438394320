import { APP_CONFIG, USER_LOGIN_STATUS_LABEL } from 'app-config';
import { createSortDescription } from 'Components/Table/createColumns';
import { GetUsersRequest, UserLoginStatus } from 'Services/Api/Users/Types';

export enum UserActionModalName {
	Confirm = 'Confirm',
	Restore = 'Restore',
	Move = 'Move'
}

export enum UserActionConfirm {
	AutoActivate = 'AutoActivate',
	Activate = 'Activate',
	Disable = 'Disable',
	Delete = 'Delete',
	ResetPassword = 'ResetPassword'
}

export enum UsersTableDeletedStatus {
	All = 'users.constants.options.allUsers',
	Deleted = 'users.constants.options.deletedUsers'
}

export const USER_STATUSES = [
	{
		text: USER_LOGIN_STATUS_LABEL[UserLoginStatus.LockedOut],
		value: UserLoginStatus.LockedOut
	},
	{
		text: USER_LOGIN_STATUS_LABEL[UserLoginStatus.ActivationPending],
		value: UserLoginStatus.ActivationPending
	}
];

export const GET_USERS_INITIAL_PARAMS: GetUsersRequest = {
	itemsPerPage: APP_CONFIG.TABLE_PAGE_SIZE,
	page: 1,
	sort: createSortDescription({
		columnKey: 'cognitoLogin',
		order: 'ascend'
	}),
	filters: {
		isActive: [true],
		isDeleted: false
	}
};

export const CONFIRM_MODAL_BY_ACTION = {
	[UserActionConfirm.AutoActivate]: {
		title: (list: string[]) => ({
			key: 'Auto Activate Selected Users',
			params: { name: list[0] }
		}),
		description: '',
		okText: 'Auto Activate'
	},
	[UserActionConfirm.Activate]: {
		title: (list: string[]) => ({
			key: `users.components.activateModal.title.${
				list.length === 1 ? 'single' : 'multiple'
			}`,
			params: { name: list[0] }
		}),
		description: `users.components.activateModal.description`,
		okText: 'users.components.usersTableControls.activate'
	},
	[UserActionConfirm.Disable]: {
		title: (list: string[]) => ({
			key: `users.components.inactivateModal.title.${
				list.length === 1 ? 'single' : 'multiple'
			}`,
			params: { name: list[0] }
		}),
		description: `users.components.inactivateModal.description`,
		okText: 'users.components.usersTableControls.inactivate'
	},
	[UserActionConfirm.Delete]: {
		title: (list: string[]) => ({
			key: `users.components.deleteModal.title.${
				list.length === 1 ? 'single' : 'multiple'
			}`,
			params: { name: list[0] }
		}),
		description: `users.components.deleteModal.description`,
		okText: 'delete'
	},
	[UserActionConfirm.ResetPassword]: {
		title: (list: string[]) => ({
			key: `users.components.resetUserPasswordsModal.title.${
				list.length === 1 ? 'single' : 'multiple'
			}`,
			params: { name: list[0] }
		}),
		description: `users.components.resetUserPasswordsModal.description`,
		okText: 'users.components.usersTableControls.resetPassword'
	}
};
