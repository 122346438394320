import { useEffect } from 'react';

import { Spin } from 'Components';
import { useStores } from 'Hooks/useStore';
import { useHistory } from 'react-router';
import { getAppRoutes } from 'Pages/App/App.constants';

function Logout() {
	const { authStore } = useStores();
	const history = useHistory();
	const signOut = async () => {
		await authStore.signOut();
		history.push(getAppRoutes().LOGIN)
		window.location.reload();
	};

	useEffect(() => {
		signOut();
		// eslint-disable-next-line
	}, []);

	return <Spin />;
}

export default Logout;
