import { gql } from '@apollo/client';

export const GET_GROUPS_LIST = gql`
	query ListGroups($filters: ListGroupsFilter, $sort: String) {
		listGroups(filters: $filters, sort: $sort) {
			id
			name
			parentId
			active
			syncCode
			isProperty
			level
			isArchived
			moduleSettings {
				moduleEnabled
			}
			deletedDate
		}
	}
`;

export const GET_DELETED_GROUPS = gql`
	query ListDeletedGroups(
		$filters: ListDeletedGroupsFilter
		$search: String
		$sort: String
	) {
		listDeletedGroups(filters: $filters, search: $search, sort: $sort) {
			id
			name
			isProperty
			tenantName
			syncCode
			hierarchy
			daysBeforeRemoval
			isArchived
			active
			deletedDate
		}
	}
`;

export const GET_GROUP = gql`
	query getGroup($id: Int!) {
		getGroup(id: $id) {
			id
			name
			parentId
			active
			syncCode
			isProperty
			isTenant
			isArchived
			level
			tenantId
			canBeProperty
			systemId
			moduleSettings {
				id
				moduleEnabled
				spotCheckNumber
				viewAllCourses
			}
			parents {
				id
				name
				moduleSettings {
					moduleEnabled
				}
				isProperty
			}
			autoactivate
			sso
			domains
		}
	}
`;

export const GET_OWNERSHIP_GROUPS = gql`
	query listOwnershipGroups($filters: ListOwnershipGroupsFilter) {
		listOwnershipGroups(filters: $filters) {
			id
			name
			status
		}
	}
`;

export const GET_OWNERSHIP_GROUP = gql`
	query getOwnershipGroupById($id: Int!) {
		getOwnershipGroupById(id: $id) {
			id
			name
			status
			groups {
				id
				name
			}
		}
	}
`;

export const GET_OWNERSHIP_GROUP_PROPERTIES = gql`
	query listOwnershipGroupProperties(
		$id: Int
		$filters: ListOwnershipGroupPropertiesFilter
		$sort: String
	) {
		listOwnershipGroupProperties(id: $id, filters: $filters, sort: $sort) {
			id
			property
			tenantName
			status
			isDeleted
			isArchived
		}
	}
`;

export const GET_PROPERTIES = gql`
	query listProperties($filters: ListPropertiesFilter) {
		listProperties(filters: $filters) {
			id
			name
			status
			tenantId
			isProperty
			isTenant
			isArchived
			deletedDate
		}
	}
`;

export const GET_GROUP_NAMES_TEMPLATE = gql`
	query getGroupNamesTemplate {
		getGroupNamesTemplate {
			downloadUrl
		}
	}
`;
export const GET_HIERARCHY_SIZE = gql`
	query getHierarchySize($input: GetHierarchySizeInput) {
		getHierarchySize(input: $input) {
			totalCount
		}
	}
`;

export const EXPORT_OWNERSHIP_GROUPS = gql`
	query exportOwnershipGroups(
		$id: Int
		$filters: ListOwnershipGroupPropertiesFilter
		$sort: String
		$downloadId: String
	) {
		exportOwnershipGroups(
			id: $id
			filters: $filters
			sort: $sort
			downloadId: $downloadId
		) {
			downloadUrl
		}
	}
`;

export const EXPORT_GROUPS = gql`
	query exportGroups(
		$filters: ListGroupsFilter
		$sort: String
		$downloadId: String
	) {
		exportGroups(filters: $filters, sort: $sort, downloadId: $downloadId) {
			downloadUrl
		}
	}
`;

export const EXPORT_DELETED_GROUPS = gql`
	query exportDeletedGroups(
		$search: String
		$filters: ListDeletedGroupsFilter
		$sort: String
		$downloadId: String
	) {
		exportDeletedGroups(
			search: $search
			filters: $filters
			sort: $sort
			downloadId: $downloadId
		) {
			downloadUrl
		}
	}
`;

export const GET_GROUPS_ANCESTOR_IDS = gql`
	query getRootAncestorIds($input: GetRootAncestorIds) {
		getRootAncestorIds(input: $input) {
			ids
		}
	}
`;

export const SEARCH_GROUPS = gql`
	query searchGroups(
		$filters: SearchGroupsFilter
		$page: Int
		$itemsPerPage: Int
	) {
		searchGroups(
			filters: $filters
			page: $page
			itemsPerPage: $itemsPerPage
		) {
			groups {
				id
				parentId
				name
				path
			}
			totalCount
		}
	}
`;

export const GROUP_HAS_PROPERTY = gql`
	query groupHasProperty($id: Int, $filters: GroupHasPropertyFilters) {
		groupHasProperty(id: $id, filters: $filters)
	}
`;

export const GET_GROUPS = gql`
	query GetGroups($input: GroupIds!) {
		getGroups(input: $input) {
			id
			name
			moduleSettings {
				moduleEnabled
			}
		}
	}
`;
