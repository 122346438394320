import { useEffect } from 'react';
import { FormItem, Input, Layout, Select } from 'Components';
import {
	GROUP_ACTIVE_STATUS_LABEL_MAP,
	GROUP_ACTIVE_STATUS_LIST
} from 'app-config';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStores } from 'Hooks/useStore';
import { Group } from 'Services/Api/Groups/Types';
import {
	LMSSettings,
	GroupField,
	ArchivedField,
	AutoactivateField,
	SsoField,
} from 'Pages/Group/AddGroup/components';
import { Entity } from 'app-types';
import { generatePath, useHistory } from 'react-router-dom';
import styles from './AddGroup.module.scss';
import { getAppRoutes } from 'Pages/App/App.constants';
import { PromptHelpers } from 'Components/Prompt/Prompt';
import { AddGroupValues } from '../Group.types';
import PropertyField from '../EditGroup/tabs/Details/PropertyField';
import PropertiesSearch from 'Pages/OwnershipGroup/AddOwnershipGroup/PropertiesField/PropertiesSearch';

export interface AddGroupFormFieldsPropsBase {
	isTenant: boolean;
	group: Group | undefined;
}
type AddGroupFormFieldsProps = PromptHelpers & AddGroupFormFieldsPropsBase;
function AddGroupFormFields({
	isBlocked,
	setBlockTransition,
	isTenant,
	group
}: AddGroupFormFieldsProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const { values, dirty, isSubmitting } = useFormikContext<AddGroupValues>();
	const { systemsStore } = useStores();
	useEffect(() => {
		setBlockTransition(dirty && !isSubmitting);
	}, [setBlockTransition, dirty, isSubmitting]);

	const breadcrumbs: Entity[] = [...(group?.parents || [])];
	if (group) {
		breadcrumbs.push({
			id: group.id,
			name: group.name
		});
	}

	return (
		<Layout.List>
			<Layout.List.ListItem title="Details" wrapInBody={false}>
				{breadcrumbs.length > 0 && (
					<div className={styles.breadcrumbs}>
						<span className={styles.breadcrumbsTitle}>
							{t('group.addGroup.youAreHere')}
							:&nbsp;&nbsp;
						</span>
						<span className={styles.breadcrumbsList}>
							{breadcrumbs.map((breadcrumb) => (
								<span key={breadcrumb.id}>
									{breadcrumb.name}
								</span>
							))}
						</span>
					</div>
				)}
				<Layout.List.ListItemBody>
					<Layout.List.Section
						title={isTenant ? t('general') : undefined}
					>
						<Input.FormikField
							name="name"
							label={t(
								isTenant
									? 'group.tenantName'
									: 'group.groupName'
							)}
							required
						/>
						<AutoactivateField />
						
						{isTenant && values.autoactivate && (
							<SsoField />
						)}
						
						{isTenant && (
							<Input.FormikField
								name="tenantId"
								label={t('group.tenantID')}
								required
							/>
						)}
						{values.parent && (
							<FormItem label={t('group.parentGroup')} required>
								<GroupField
									initialValue={[
										{
											key: values.parent.id,
											title: values.parent.name
										}
									]}
									onSubmit={(value) => {
										const isTransitionBlocked = isBlocked;
                    setBlockTransition(false);
                    // it allows to be sure that we unblocked transition before push to another place in the browser history
                    setTimeout(()=>{
                      history.push(
                        generatePath(
                          getAppRoutes().ADD_GROUP,
                          {
                            groupId: value.key
                          }
                        )
                      );
                       setBlockTransition(isTransitionBlocked);
                    }, 0)

									}}
								/>
							</FormItem>
						)}
						{values.tenant && (
							<FormItem label={t('group.tenant')} required>
								<Select
									value={{
										key: values.tenant.id,
										value: values.tenant.id,
										label: values.tenant.name
									}}
									labelInValue
									disabled
								/>
							</FormItem>
						)}
						<Select.FormikField
							name="active"
							label={t('group.status')}
							required
						>
							{GROUP_ACTIVE_STATUS_LIST.map((status) => (
								<Select.Option key={status} value={status}>
									{GROUP_ACTIVE_STATUS_LABEL_MAP[status]()}
								</Select.Option>
							))}
						</Select.FormikField>
						<ArchivedField />
						<Input.FormikField
							name="syncCode"
							label={t('group.syncCode')}
						/>
						<PropertyField />
						{isTenant && (
							<>
								<Select.FormikField
									name="systemId"
									label={t('group.systemName')}
									required
								>
									{systemsStore.systems.map((system) => (
										<Select.Option
											key={system.id}
											value={system.id}
										>
											{system.systemName}
										</Select.Option>
									))}
								</Select.FormikField>
							</>
						)}
					</Layout.List.Section>
					{isTenant && (
						<Layout.List.Section
							title={t('group.editGroup.sections.domainSettings')}
						>
							<Input.FormikField
								name="field1"
								label={t('group.editGroup.inputs.fromName')}
								disabled
							/>
							<Input.FormikField
								name="field2"
								label={t('group.editGroup.inputs.fromEmail')}
								disabled
							/>
							<Input.FormikField
								name="field3"
								label={t('group.editGroup.inputs.replyTo')}
								disabled
							/>
							<Input.FormikField
								name="field4"
								label={t('group.editGroup.inputs.SMTPServer')}
								disabled
							/>
							<Input.FormikField
								name="field5"
								label={t('group.editGroup.inputs.SMTPUserName')}
								disabled
							/>
							<Input.FormikField
								name="field6"
								label={t('group.editGroup.inputs.SMTPPassword')}
								disabled
							/>
							<Input.FormikField
								name="field7"
								label={t('group.editGroup.inputs.domain')}
								disabled
							/>
						</Layout.List.Section>
					)}
				</Layout.List.ListItemBody>
			</Layout.List.ListItem>
			<Layout.List.ListItem
				title={t('group.editGroup.sections.moduleSettings')}
			>
				<Layout.List.Section>
					<LMSSettings isTenant={isTenant} />
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
}

export default AddGroupFormFields;
